import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { pontoParadaStore, rotaPontoParadaStore, rotaStore, veiculoStore, } from '@/store';
import draggable from 'vuedraggable';
import SaveButton from '@/components/SaveButton.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newRota } from '@/interfaces/rota';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import _ from 'lodash';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Create = class Create extends Vue {
    constructor() {
        super(...arguments);
        this.newTrajetoIda = false;
        this.allowEditTrajetoIda = false;
        this.newTrajetoVolta = false;
        this.allowEditTrajetoVolta = false;
        this.search = '';
        this.expanded = [];
        this.id = null;
        this.salvando = false;
        this.rotaPontosParadaIda = [];
        this.rotaPontosParadaVolta = [];
        this.entidadeAlunoSelected = null;
        this.entidadeAlunoSelectedVolta = null;
        this.rotaPontosParada = [];
        this.veiculoSelected = null;
        this.drag = false;
        this.entityObject = null;
        this.selectedItem = {};
        this.idIda = 3;
        this.rotaAlunos = {};
        this.tempTrajetoIda = [];
        this.tempTrajetoVolta = [];
    }
    get validRotas() {
        const notValidVolta = _.some(this.rotaPontosParadaVolta, (element) => {
            return !element.aluno || !element.ponto_parada || !element.hora;
        });
        const notValidIda = _.some(this.rotaPontosParadaIda, (element) => {
            return !element.aluno || !element.ponto_parada || !element.hora;
        });
        return !notValidVolta && !notValidIda;
    }
    get headers() {
        return [
            { text: 'Ponto de parada', value: 'ponto_parada_id', width: '20%' },
            { text: 'Aluno', value: 'aluno_id', width: '20%' },
            {
                text: 'Horário',
                value: 'hora',
                width: this.allowEditTrajetoIda || this.allowEditTrajetoVolta ? '14%' : '4%',
            },
        ];
    }
    get rotas() {
        return rotaStore.rotas;
    }
    get veiculosOptions() {
        return veiculoStore.veiculosActive;
    }
    get itemSendoEditado() {
        const editandoIda = _.some(this.rotaPontosParadaIda, (element) => {
            return element.edit;
        });
        const editandoVolta = _.some(this.rotaPontosParadaVolta, (element) => {
            return element.edit;
        });
        return editandoIda || editandoVolta;
    }
    get dragOptions() {
        return {
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
        };
    }
    get pontoParadaOptions() {
        return pontoParadaStore.pontosParada;
    }
    async printItem(rotaId) {
        const bytes = await rotaStore.getRotaPDF(rotaId);
        if (bytes) {
            const w = window.open();
            w.document.write(bytes);
            setTimeout(() => {
                w.print();
            }, 1000);
        }
    }
    confirmarEdit(trajeto_ida) {
        if (trajeto_ida) {
            if (this.newTrajetoIda) {
                this.newTrajetoIda = false;
            }
            if (this.allowEditTrajetoIda) {
                this.allowEditTrajetoIda = false;
            }
            _.forEach(this.rotaPontosParadaIda, (item) => {
                if (item.ponto_parada) {
                    item.ponto_parada_id = item.ponto_parada.id;
                }
                if (item.aluno?.nome && item.aluno) {
                    item.aluno.aluno_nome = item.aluno.nome;
                    item.aluno_id = item.aluno.id;
                }
                if (item.edit) {
                    item.edit = false;
                }
            });
        }
        else {
            if (this.newTrajetoVolta) {
                this.newTrajetoVolta = false;
            }
            if (this.allowEditTrajetoVolta) {
                this.allowEditTrajetoVolta = false;
            }
            _.forEach(this.rotaPontosParadaVolta, (item) => {
                if (item.ponto_parada) {
                    item.ponto_parada_id = item.ponto_parada.id;
                }
                if (item.aluno?.nome && item.aluno) {
                    item.aluno.aluno_nome = item.aluno.nome;
                    item.aluno_id = item.aluno.id;
                }
                if (item.edit) {
                    item.edit = false;
                }
            });
        }
    }
    adicionarItem(trajeto_ida) {
        if (trajeto_ida) {
            const temp = [];
            if (!this.rotaPontosParadaIda) {
                this.rotaPontosParadaIda = [];
            }
            _.forEach(this.rotaPontosParadaIda, (item) => {
                temp.push({ ...item });
            });
            this.tempTrajetoIda = temp;
            this.rotaPontosParadaIda.push({
                edit: true,
            });
            this.newTrajetoIda = true;
        }
        else {
            const temp = [];
            if (!this.rotaPontosParadaVolta) {
                this.rotaPontosParadaVolta = [];
            }
            _.forEach(this.rotaPontosParadaVolta, (item) => {
                temp.push({ ...item });
            });
            this.tempTrajetoVolta = temp;
            this.rotaPontosParadaVolta.push({
                edit: true,
            });
            this.newTrajetoVolta = true;
        }
    }
    removeItem(trajeto_ida, value) {
        const idx = trajeto_ida
            ? this.rotaPontosParadaIda.indexOf(value)
            : this.rotaPontosParadaVolta.indexOf(value);
        trajeto_ida
            ? this.rotaPontosParadaIda.splice(idx, 1)
            : this.rotaPontosParadaVolta.splice(idx, 1);
        trajeto_ida ? (this.newTrajetoIda = false) : (this.newTrajetoVolta = false);
    }
    toggleEditar(trajeto_ida) {
        if (trajeto_ida) {
            this.allowEditTrajetoIda = !this.allowEditTrajetoIda;
            const temp = [];
            _.forEach(this.rotaPontosParadaIda, (item) => {
                if (item.aluno) {
                    item.aluno.nome = item.aluno.aluno_nome
                        ? item.aluno.aluno_nome
                        : item.aluno.nome;
                    item.aluno.tipo = 'Aluno';
                }
                temp.push({ ...item });
            });
            this.tempTrajetoIda = temp;
        }
        else {
            this.allowEditTrajetoVolta = !this.allowEditTrajetoVolta;
            const temp = [];
            _.forEach(this.rotaPontosParadaVolta, (item) => {
                if (item.aluno) {
                    item.aluno.nome = item.aluno.aluno_nome
                        ? item.aluno.aluno_nome
                        : item.aluno.nome;
                    item.aluno.tipo = 'Aluno';
                }
                temp.push({ ...item });
            });
            this.tempTrajetoVolta = temp;
        }
    }
    cancelarEdit(trajeto_ida) {
        if (trajeto_ida) {
            if (this.tempTrajetoIda) {
                this.rotaPontosParadaIda = this.tempTrajetoIda;
            }
            this.allowEditTrajetoIda = false;
            this.newTrajetoIda = false;
        }
        else {
            if (this.tempTrajetoVolta) {
                this.rotaPontosParadaVolta = this.tempTrajetoVolta;
            }
            this.allowEditTrajetoVolta = false;
            this.newTrajetoVolta = false;
        }
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        if (this.id) {
            _.forEach(this.rotaPontosParadaIda, (item) => {
                item.aluno_id = item.aluno.id;
            });
            _.forEach(this.rotaPontosParadaVolta, (item) => {
                item.aluno_id = item.aluno.id;
            });
            this.entityObject.veiculo_id = this.veiculoSelected.id;
            this.entityObject.veiculo = this.veiculoSelected;
            const result = await rotaStore.updateRota({
                rota: this.entityObject,
                pontos_parada_ida: this.rotaPontosParadaIda,
                pontos_parada_volta: this.rotaPontosParadaVolta,
                ponto_parada_alunos: this.rotaAlunos,
            });
        }
        else {
            this.entityObject.veiculo_id = this.veiculoSelected.id;
            this.entityObject.veiculo = this.veiculoSelected;
            const result = await rotaStore.createRota(this.entityObject);
            this.id = result;
            this.entityObject.id = result;
            await rotaStore.updateRota({
                rota: this.entityObject,
                pontos_parada_ida: this.rotaPontosParadaIda,
                pontos_parada_volta: this.rotaPontosParadaVolta,
                ponto_parada_alunos: this.rotaAlunos,
            });
        }
        await this.$router.push({ name: 'main-rotas' });
    }
    get alunoSemTransporte() {
        return rotaStore.alunoSemTransporte;
    }
    async mounted() {
        await veiculoStore.getVeiculos();
        // @ts-ignore
        if (this.$router.currentRoute.params.id) {
            // @ts-ignore
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await rotaStore.getRota(parseInt(this.id, 0));
            const rotaPontosParada = await rotaPontoParadaStore.getRotaPontosParada(this.id);
            this.veiculoSelected = this.entityObject.veiculo;
            this.rotaPontosParadaIda = _.filter(rotaPontosParada, (item) => item.trajeto_ida === true);
            this.rotaPontosParadaVolta = _.filter(rotaPontosParada, (item) => item.trajeto_ida === false);
            await pontoParadaStore.getPontosParada();
            // this.rotaAlunos = await rotaStore.getRotaAlunos(this.id);
            // _.forEach(this.rotaPontosParadaIda, (item) => {
            //   if (!this.rotaAlunos[item.id]) {
            //     this.rotaAlunos[item.id] = [];
            //   }
            // });
            // _.forEach(this.rotaPontosParadaVolta, (item) => {
            //   if (!this.rotaAlunos[item.id]) {
            //     this.rotaAlunos[item.id] = [];
            //   }
            // });
        }
        else {
            this.entityObject = newRota();
            await pontoParadaStore.getPontosParada();
        }
        await rotaStore.getAlunoSemTransporte();
    }
};
Create = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            draggable,
            EntidadeLinkComponent,
            SearchEntidadeComponent,
            SaveButton,
            TextExpandableComponent,
        },
    })
], Create);
export default Create;
